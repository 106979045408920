var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[(!_vm.disabledSettings)?_c('v-card',[_c('v-card-title',[_vm._v("Настройки компании:")]),_c('v-card-text',[_c('div',{attrs:{"id":"truck-kinds"}},[_c('v-select',{style:({ 'max-width': '400px' }),attrs:{"label":"Используемые виды транспорта","items":_vm.$store.getters.allTruckKinds,"clearable":"","multiple":""},model:{value:(_vm.settings.truckKinds),callback:function ($$v) {_vm.$set(_vm.settings, "truckKinds", $$v)},expression:"settings.truckKinds"}}),_c('v-select',{style:({ 'max-width': '220px' }),attrs:{"label":"Вид транспорта по умолчанию","items":_vm.$store.getters.allTruckKinds.filter((i) =>
              _vm.settings.truckKinds.includes(i.value)
            ),"clearable":""},model:{value:(_vm.settings.defaultTruckKind),callback:function ($$v) {_vm.$set(_vm.settings, "defaultTruckKind", $$v)},expression:"settings.defaultTruckKind"}})],1),_c('div',{attrs:{"id":"lift-capacity-types"}},[_c('v-select',{style:({ 'max-width': '400px' }),attrs:{"label":"Типы грузоподъемности","items":_vm.$store.getters.allLiftCapacityTypes,"multiple":"","clearable":""},model:{value:(_vm.settings.liftCapacityTypes),callback:function ($$v) {_vm.$set(_vm.settings, "liftCapacityTypes", $$v)},expression:"settings.liftCapacityTypes"}}),_c('v-select',{style:({ 'max-width': '220px' }),attrs:{"label":"Грузоподъемность по умолчанию","items":_vm.$store.getters.allLiftCapacityTypes.filter((i) =>
              _vm.settings.liftCapacityTypes.includes(i)
            ),"clearable":""},model:{value:(_vm.settings.defaultLiftCapacity),callback:function ($$v) {_vm.$set(_vm.settings, "defaultLiftCapacity", $$v)},expression:"settings.defaultLiftCapacity"}})],1),(_vm.showLoadDirectionSettings)?_c('div',{attrs:{"id":"load-directions"}},[_c('v-select',{style:({ 'max-width': '400px' }),attrs:{"label":"Варианты загрузки","items":_vm.$store.getters.allLoadDirection,"multiple":"","clearable":""},model:{value:(_vm.settings.loadDirections),callback:function ($$v) {_vm.$set(_vm.settings, "loadDirections", $$v)},expression:"settings.loadDirections"}}),_c('v-select',{style:({ 'max-width': '220px' }),attrs:{"label":"Вариант загрузки по умолчанию","items":_vm.$store.getters.allLoadDirection.filter((i) =>
              _vm.settings.loadDirections.includes(i.value)
            ),"clearable":""},model:{value:(_vm.settings.defaultLoadDirection),callback:function ($$v) {_vm.$set(_vm.settings, "defaultLoadDirection", $$v)},expression:"settings.defaultLoadDirection"}})],1):_vm._e(),_c('v-textarea',{attrs:{"label":"Примечание для договора-заявки","rows":"15","outlined":""},model:{value:(_vm.settings.commonOrderContractNote),callback:function ($$v) {_vm.$set(_vm.settings, "commonOrderContractNote", $$v)},expression:"settings.commonOrderContractNote"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":!_vm.changed},on:{"click":_vm.cancel}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.changed},on:{"click":_vm.submit}},[_vm._v(" Сохранить ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }