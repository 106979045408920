export default [
  {
    value: 'name',
    text: 'Наименование',
    sortable: true,
    default: true,
  },

  {
    value: 'note',
    text: 'Примечание',
    sortable: false,
    default: true,
  },
]
